import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import Pen1 from './img/canetas/caneta-1.jpg';
import Pen1Thumb from './img/canetas/thumbnail/caneta-1.jpg';
import Pen2 from './img/canetas/caneta-2.jpg';
import Pen2Thumb from './img/canetas/thumbnail/caneta-2.jpg';
import Pen3 from './img/canetas/caneta-3.jpg';
import Pen3Thumb from './img/canetas/thumbnail/caneta-3.jpg';
import Pen4 from './img/canetas/caneta-4.jpg';
import Pen4Thumb from './img/canetas/thumbnail/caneta-4.jpg';
import Pen5 from './img/canetas/caneta-5.jpg';
import Pen5Thumb from './img/canetas/thumbnail/caneta-5.jpg';

const images = [
  {
    original: Pen1,
    thumbnail: Pen1Thumb,
    description: 'Caneta Brasil',
  },
  {
    original: Pen2,
    thumbnail: Pen2Thumb,
    description: 'Caneta Rosa',
  },
  {
    original: Pen3,
    thumbnail: Pen3Thumb,
    description: 'Caneta Seu Nome',
  },
  {
    original: Pen4,
    thumbnail: Pen4Thumb,
    description: 'Caneta Rosa',
  },
  {
    original: Pen5,
    thumbnail: Pen5Thumb,
    description: 'Caneta Ursinho',
  },
];

function Slide() {
  return (
    <div>
      <ImageGallery items={images} autoPlay={false} thumbnailPosition="left" />
    </div>
  );
}

export default Slide;
