import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

// Importar Imagens
import Lino1 from './img/peixinho-passaro/lino_caio_onea.png';
import Lino1Thumb from './img/peixinho-passaro/thumbnail/thub_onea.png';
import Lino2 from './img/peixinho-passaro/lino_caio_two.png';
import Lino2Thumb from './img/peixinho-passaro/thumbnail/thub_two.png';
import Lino3 from './img/peixinho-passaro/lino_caio_three.png';
import Lino3Thumb from './img/peixinho-passaro/thumbnail/thub_three.png';

const images = [
  {
    original: Lino1,
    thumbnail: Lino1Thumb,
    description: 'Lino observando o céu com pássaros voando.',
  },
  {
    original: Lino2,
    thumbnail: Lino2Thumb,
    description: 'Caio caiu na beira do lago',
  },
  {
    original: Lino3,
    thumbnail: Lino3Thumb,
    description: 'Lino voando entre as nuvens.',
  },
];

function Slide() {
  return <ImageGallery items={images} autoPlay />;
}

export default Slide;
