import './footer.css';
import { Link } from 'react-router-dom';
import Facebook from './icofacebook.png';
import Instagram from './icoinstagram.png';
import Whatsapp from './iconwhatsapp.png';

export default function Footer() {
  function getAno() {
    const dataAtual = new Date();
    const anoAtual = dataAtual.getFullYear();
    return anoAtual;
  }

  return (
    // MENU SECONDARY TERÁ EM TODAS AS PÁGINAS:
    <footer>
      <div className="rodape">
        <div className="menu-s">
          <ul className="menu-s__list">
            <h2 className="menu__title">Institucional</h2>
            <li className="menu-s__item">
              <Link className="menu-s__action" to="/sobre-nos">
                Sobre Nós
              </Link>
            </li>
            <li className="menu-s__item">
              <Link className="menu-s__action" to="/contato">
                Contato
              </Link>
            </li>
          </ul>
          <ul className="menu-s__list">
            <h2 className="menu__title">Informações</h2>
            <li className="menu-s__item">
              <Link className="menu-s__action" to="/atelie">
                Ateliê
              </Link>
            </li>
            <li className="menu-s__item">
              <Link className="menu-s__action" to="/blog">
                Blog
              </Link>
            </li>
          </ul>
        </div>

        <ul className="rodape__list">
          <li className="rodape__item">
            <a
              title="Whatsapp"
              target="_blank"
              rel="noreferrer"
              href="https://wa.me/5588992882135?text=Olá,%20Tenho%20interesse%20em%20comprar: %20"
            >
              <img src={Whatsapp} alt="Whatsapp" className="rodape__whats" />
            </a>
          </li>
          <li className="rodape__item">
            <a
              title="Facebook"
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/MichelleLimasOficial"
            >
              <img src={Facebook} alt="Facebook" className="rodape__face" />
            </a>
          </li>
          <li className="rodape__item">
            <a
              title="Instagram"
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/michelle.eatelie/"
            >
              <img src={Instagram} alt="Instagram" className="rodape__insta" />
            </a>
          </li>
        </ul>
      </div>
      <div className="rodape__copyright">
        &copy; Copyright 2020- {getAno()} Michelle Ateliê
      </div>
    </footer>
  );
}
