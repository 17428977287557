import './index.css';
import LinoA from './lino_caio/lino_caio_onea.png';
import LinoB from './lino_caio/lino_caio_two.png';
import LinoC from './lino_caio/lino_caio_three.png';

function Fabula() {
  return (
    <section className="main">
      {/* <h1 className='main__title'>Fábula</h1> */}
      <article className="fabula">
        <h1 className="fabula__title">Fábula: O Peixinho e o Pássaro</h1>

        <p className="fabula__paragraph">
          Lino era um peixinho que vivia em uma lagoa e sonhava em voar pelo céu
          junto com os outros pássaros e fazer todas aquelas acrobacias no ar,
          mas percebia que era impossível, pois não teria como respirar além de
          não ter asas para voar.
        </p>

        <img
          src={LinoA}
          className="fabula__responsive"
          alt="Lino observando o céu com pássaro voando"
        />

        <p className="fabula__paragraph">
          Lá no Céu viviam vários pássaros que gostavam de fazer diversas
          brincadeiras no ar. Um desses era Caio, um passarinho muito simpático
          e cheio de amiguinhos.
        </p>

        <p className="fabula__paragraph">
          Todos os dias o peixinho Lino ficava admirando os pássaros brincarem
          no céu com todas aquelas cores que se formavam no céu.
        </p>

        <p className="fabula__paragraph">
          Um certo dia Lino estava como de costume olhando os pássaros
          sobrevoando sobre a lagoa onde morava, neste dia o grupo de voadores
          estavam todos felizes passando pelas nuvens, pois o dia estava lindo e
          ensolarado. Lá, um pouco distante, se encontra um gavião que não
          gostava de brincadeira com os passarinhos, na verdade, não gostava de
          ver toda aquela alegria.
        </p>

        <p className="fabula__paragraph">
          Então, ele para acabar com a diversão, decidiu invadir o meio da
          brincadeira e assustar os pássaros para saírem da diversão.
        </p>

        <p className="fabula__paragraph">
          O gavião decidiu vir na frente, enquanto todos voavam alegres, Caio
          estava distraído e acabou esbarrando no gavião, que por sua vez era
          bem maior.
        </p>

        <p className="fabula__paragraph">
          Caio acabou desmaiando e caindo na lagoa, enquanto os outros pássaros
          nem perceberam o amigo, pois estavam todos assustados com o gavião.
        </p>

        <p className="fabula__paragraph">
          Lino viu tudo o que aconteceu e estava atento na hora do pássaro
          caindo em direção a lagoa.
        </p>

        <p className="fabula__paragraph">
          Quando Caio caiu na água, o peixinho foi logo socorrê-lo, levando-o
          nas costas até chegar em uma pedra que ficava bem na beira da lagoa.
        </p>
        <p className="fabula__paragraph">
          Lino ao colocar o pássaro na pedra ficou ao lado dele até que
          acordasse.
        </p>
        <img
          src={LinoB}
          className="fabula__responsive"
          alt="Caio caiu na beira do lago"
        />
        <p className="fabula__paragraph">
          Ao acordar do susto, Caio perguntou ao peixinho onde estava e o que
          tinha acontecido com ele.
        </p>

        <p className="fabula__paragraph">
          Lino explicou tudo o que tinha acontecido, todos os detalhes que viu.
        </p>

        <p className="fabula__paragraph">
          Após ter explicado, Caio perguntou também como ele sabia de tudo o que
          tinha acontecido, e Lino respondeu que todos os dias observava os
          pássaros, pois sonhava em voar tão alto como as nuvens, mas que estava
          triste, porque não tinha como realizar o sonho.
        </p>

        <p className="fabula__paragraph">
          Pensando sobre o que o seu novo amigo tinha dito, Caio logo teve uma
          ideia, pedindo que Lino aguardasse alguns minutos que logo ele
          voltaria. Sem saber o que estava acontecendo, Lino esperou, e logo
          percebeu seu amigo voltando com um vidro grande que o caberia, então
          encheu de água e pediu que Lino entrasse, pois ele iria realizar o
          sonho do seu amigo que tinha salvado sua vida.
        </p>

        <p className="fabula__paragraph">
          Lino entrou no vidro e logo o pássaro o colocou nas costas, começando
          a subir lentamente até chegar nas nuvens.
        </p>
        <img
          src={LinoC}
          className="fabula__responsive"
          alt="Lino voando entre as nuvens"
        />
        <p className="fabula__paragraph">
          Lino ficou muito emocionado pois naquele dia ganhou dois valiosos
          presentes, realizou o sonho de voar e ganhou um grande amigo.
        </p>
        <br />
        <span className="fabula__author">
          {' '}
          <em>
            <strong> Escrito por:</strong> Michelle Lima{' '}
          </em>
        </span>
      </article>
    </section>
  );
}

export default Fabula;
