import React from 'react';
import styled from 'styled-components';
import Title from '../Title';

const Para = styled.p`
  height: 20vh;
  text-align: center;
  margin-top: 10px;
`;

function RecebidoEmail() {
  return (
    <>
      <Title content="Mensagem enviada!" />
      <Para>Nossa equipe, em breve responderá.</Para>
    </>
  );
}

export default RecebidoEmail;
