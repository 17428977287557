import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const images = [
  {
    original: './image/atelie/categoria/casa/pano-prato/pano1.jpg',
    thumbnail: './image/atelie/categoria/casa/pano-prato/thumbnail/pano1.jpg',
    description: 'Pano de Prato Verde',
  },
  {
    original: './image/atelie/categoria/casa/pano-prato/pano2.jpg',
    thumbnail: './image/atelie/categoria/casa/pano-prato/thumbnail/pano2.jpg',
    description: 'Pano de Prato Amarelo',
  },
  {
    original: './image/atelie/categoria/casa/pano-prato/pano1.jpg',
    thumbnail: './image/atelie/categoria/casa/pano-prato/thumbnail/pano1.jpg',
    description: 'Pano de Prato Verde',
  },
];

function Slide() {
  return (
    <div className="square-slide">
      <ImageGallery items={images} autoPlay={false} thumbnailPosition="left" />
    </div>
  );
}

export default Slide;
