import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import styled from 'styled-components';
import Bri1 from './img/brincos/brinco1.jpg';
import ThumbBri1 from './img/brincos/thumbnail/brinco1.jpg';
import Bri2 from './img/brincos/brinco2.jpg';
import ThumbBri2 from './img/brincos/thumbnail/brinco2.jpg';
import Bri3 from './img/brincos/brinco3.jpg';
import ThumbBri3 from './img/brincos/thumbnail/brinco3.jpg';
import Bri4 from './img/brincos/brinco4.jpg';
import ThumbBri4 from './img/brincos/thumbnail/brinco4.jpg';
import Bri5 from './img/brincos/brinco5.jpg';
import ThumbBri5 from './img/brincos/thumbnail/brinco5.jpg';
import Bri6 from './img/brincos/brinco6.jpg';
import ThumbBri6 from './img/brincos/thumbnail/brinco6.jpg';
import Bri7 from './img/brincos/brinco7.jpg';
import ThumbBri7 from './img/brincos/thumbnail/brinco7.jpg';
import Bri8 from './img/brincos/brinco8.jpg';
import ThumbBri8 from './img/brincos/thumbnail/brinco8.jpg';
import Bri9 from './img/brincos/brinco9.jpg';
import ThumbBri9 from './img/brincos/thumbnail/brinco9.jpg';

const images = [
  {
    original: Bri1,
    thumbnail: ThumbBri1,
    description: 'Brinco de crochê vermelho',
  },
  {
    original: Bri2,
    thumbnail: ThumbBri2,
    description: 'Brinco de crochê verde',
  },
  {
    original: Bri3,
    thumbnail: ThumbBri3,
    description: 'Brinco de crochê lilás',
  },
  {
    original: Bri4,
    thumbnail: ThumbBri4,
    description: 'Brinco de crochê laranja',
  },
  {
    original: Bri5,
    thumbnail: ThumbBri5,
    description: 'Brinco de crochê amarelo',
  },
  {
    original: Bri6,
    thumbnail: ThumbBri6,
    description: 'Brinco de crochê roso',
  },
  {
    original: Bri7,
    thumbnail: ThumbBri7,
    description: 'Brinco de crochê amarelo',
  },
  {
    original: Bri8,
    thumbnail: ThumbBri8,
    description: 'Brinco de crochê laranja',
  },
  {
    original: Bri9,
    thumbnail: ThumbBri9,
    description: 'Brinco de crochê marron',
  },
];

const Central = styled.div`
  width: 95vw;
  margin: 10px;

  @media (min-width: 501px) {
    width: 100%;
  }
`;
function Slide() {
  return (
    <Central>
      <ImageGallery items={images} autoPlay={false} thumbnailPosition="left" />
    </Central>
  );
}

export default Slide;
