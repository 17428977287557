/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './img/logo.png';
import './menu.css';

function Menu() {
  function menuMobile(e) {
    e.preventDefault();
    const btnMenu = document.querySelector('.btn__menu');
    const menuList = document.querySelector('.menu__list');

    btnMenu.classList.toggle('active');
    menuList.classList.toggle('active');
  }

  return (
    <header className="menu">
      <nav className="menu__nav">
        <Link to="/" id="logo">
          <img className="menu__logo" src={Logo} alt="Michelle Ateliê" />{' '}
        </Link>
        <ul id="menu" className="menu__list">
          <li onClick={menuMobile} className="menu__item">
            <Link to="/" className="menu__action">
              <span className="item__default">Home</span>
              <span className="item__show">Home</span>
            </Link>
          </li>
          <li onClick={menuMobile} className="menu__item">
            <Link to="/atelie" className="menu__action">
              <span className="item__default">Ateliê</span>
              <span className="item__show">Ateliê</span>
            </Link>
          </li>
          {/* <li onClick={menuMobile} className='menu__item'>
                        <Link to="/encomendas" className='menu__action'>
                            <span className="item__default">Encomendas</span>
                            <span className="item__show">Encomendas</span>
                        </Link>
                    </li> */}
          <li onClick={menuMobile} className="menu__item">
            <Link to="blog" className="menu__action">
              <span className="item__default">Blog</span>
              <span className="item__show">Blog</span>
            </Link>
          </li>
          <li onClick={menuMobile} className="menu__item">
            <Link to="contato" className="menu__action">
              <span className="item__default">Contato</span>
              <span className="item__show">Contato</span>
            </Link>
          </li>
        </ul>

        <a onClick={menuMobile} href="#menu" className="btn__menu">
          {/* HAMBURGUER CRIADO POR CSS */}
          <span className="btn__bar" />
          <span className="btn__bar" />
          <span className="btn__bar" />
        </a>
      </nav>
    </header>
  );
}

export default Menu;
