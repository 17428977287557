import styled from 'styled-components';

const Form = styled.form`
  box-sizing: border-box;
  margin: 0px 20px 20px 20px;
  padding: 0px 5px 5px 5px;
  display: flex;
  flex-flow: column wrap;
  color: rgb(39, 37, 37);

  @media (min-width: 800px) {
    justify-items: center;
    padding: 5px 15%;
  }
`;
export default Form;
