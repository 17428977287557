import styled from 'styled-components'

export const Paragrafo = styled.p`
    text-indent: 2em;
`;
export const TitleC = styled.h1`
    color: --corb4;
    font-size: 30px;
    text-align: center;
    margin: 20px;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
`;
export const Image = styled.img`
    max-width: 300px;
    max-height: 300px;
`;

export const Gallery = styled.div`
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;

    @media (max-width:345px){
        margin: 2px;
        flex-direction: column;
    }
`;
