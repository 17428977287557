import './home.css';
import { Link } from 'react-router-dom';
import Atelie from './HomeAtelie';

function Home() {
  return (
    <main className="home">
      <section className="palco home-atelie">
        <Atelie />
      </section>
      {/*             
            <section className='home-encomenda' id="encomendas">
                <h1 className='home-title'>Encomendas</h1>
                <article className='artigo-secondary'>

                    <h3 className='encomenda-h3'> Como funciona? </h3>
                    <p >Peça sua encomenda pelo formulário,
                        respondendo com atenção a todas as perguntas. Depois disso, entraremos em contato
                        individualmente para finalizar sua encomenda e personalizar o seu produto.
                    </p>
                    <Link className='btnSaiba' to='/encomendas'>Saiba mais</Link>
                </article>

            </section>
             */}
      <section className="home-blog" id="blog">
        <h1 className="home-title">Blog</h1>
        <article className="artigo-secondary">
          <h1>Para você e seu filho. </h1>
          <p className="p-fabula">
            {' '}
            Temos artigos sobre as tendências da moda. E também histórias
            infantis para estimular a leitura das crianças. A recomendação que
            os pais leiam para os filhos desde de pequeno.Não deixe lê para
            eles, pois ajuda no processo de alfabetização.
          </p>
          <Link className="btnSaiba" to="/blog">
            Saiba mais
          </Link>
        </article>
      </section>
      <section
        className="mini-container home-contato artigo-secondary"
        id="contato"
      >
        <h1 className="home-title">Gostaria de falar conosco!</h1>
        <p>Então, podemos negociar pelos canais abaixo:</p>
        <ul className="menu">
          <li className="menu-list">
            <a
              className="menu-action"
              title="Whatsapp"
              href="https://wa.me/5588992882135?text=Olá,%20Tenho%20interesse%20em%20comprar: %20"
            >
              Whatsapp
            </a>
          </li>
          <li className="menu-list">
            <Link className="menu-action" title="Formulário" to="/contato">
              Formulário{' '}
            </Link>
          </li>
          <li className="menu-list">
            <a
              className="menu-action"
              title="Instagram"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/michelle.eatelie/"
            >
              Instagram
            </a>
          </li>
          <li className="menu-list">
            <a
              className="menu-action"
              title="Facebook"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/MichelleLimasOficial"
            >
              Facebook
            </a>
          </li>
        </ul>
      </section>
    </main>
  );
}

export default Home;
