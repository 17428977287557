import './atelie.css';
import FemininoGaleria from './categoria/feminino/slides';
import InfantilGaleria from './categoria/infantil/slides';
import CasaGaleria from './categoria/casa/slides';
import EscolarGaleria from './categoria/escolar/slides';

function Atelie() {
  return (
    <div className="atelie">
      <h1 className="titlePrimary">
        Ateliê
        <span className="titleSecondary">
          {' '}
          Da Michelle o crochê ideal para você.
        </span>{' '}
      </h1>
      {/* Menu Categoria */}
      <div className="atelie-main">
        <nav className="category">
          <ul className="menuList">
            <li className="menuItem"> Categorias:</li>
            <li className="menuItem">
              {' '}
              <a className="menuAction" href="#feminino">
                Feminino
              </a>
            </li>
            <li className="menuItem">
              {' '}
              <a className="menuAction" href="#infantil">
                Infantil
              </a>
            </li>
            <li className="menuItem">
              {' '}
              <a className="menuAction" href="#casa">
                Casa
              </a>
            </li>
            <li className="menuItem">
              {' '}
              <a className="menuAction" href="#escolar">
                Escolar
              </a>
            </li>
          </ul>
        </nav>
        <main>
          <section id="feminino">
            <h1 className="title-atelie">Feminino</h1>
            <h2>Brincos em crochê</h2>
            <FemininoGaleria />
          </section>
          <section id="infantil">
            <h1 className="title-atelie"> Infantil</h1>
            <h2>Prendedor de Cabelo Infantil</h2>
            <InfantilGaleria />
          </section>
          <section id="casa">
            <h1 className="title-atelie">Casa</h1>
            <h2>Pano de Prato em barrado</h2>
            <CasaGaleria />
          </section>
          <section id="escolar">
            <h1 className="title-atelie">Escolar</h1>
            <h2>Canetas Personalizada</h2>
            <EscolarGaleria />
          </section>
        </main>
      </div>
    </div>
  );
}

export default Atelie;
