import { Routes, Route } from 'react-router-dom'

import Home from './home/home'
import Fabula from './fabula';
import Atelie from './atelie/atelie';
import Contato from './contato';
import QuemSomos from './sobre-nos';
import Atendimento from './atendimento'
import Login from './login';
import RecuperaLogin from './recupera-login';
import Cadastro from './cadastro';
import Blog from './blog/blog';
import Erro404 from './404/404'

function ManagerPages() {
    return (

        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/blog' element={<Blog />} />
            <Route path='/atelie' element={<Atelie />} />
            <Route path='/fabula' element={<Fabula />} />
            <Route path='/contato' element={<Contato />} />
            <Route path='/sobre-nos' element={<QuemSomos />} />
            <Route path='/atendimento' element={<Atendimento />} />
            <Route path='/login' element={<Login />} />
            <Route path='/recupera-login' element={<RecuperaLogin />} />
            <Route path='/cadastro' element={<Cadastro />} />
            <Route path='/*' element={<Erro404 />} />
        </Routes>
    )
}
export default ManagerPages