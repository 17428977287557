import React from 'react';
import styled from 'styled-components';

const Title = styled.h1`
  text-align: center;
`;

function TitleElement({ content }) {
  return <Title>{content}</Title>;
}

export default TitleElement;
