import styled from 'styled-components';

const SectionMain = styled.section`
  display: flex;
  flex-flow: column wrap;
  gap: 5px;
  padding: 5px 20px;
`;

export default SectionMain;
