import styled from 'styled-components';

const ContainerCenter = styled.div`
  margin-top: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Loader = styled.div`
  width: 50px;
  height: 50px;
  animation: is-rotating 1s infinite;
  border: 6px solid #e5e5e5;
  border-radius: 50%;
  border-top-color: var(--corb4);

  @keyframes is-rotating {
    to {
      transform: rotate(1turn);
    }
  }
`;

function Loading() {
  return (
    <ContainerCenter>
      {/* eslint-disable-next-line react/self-closing-comp */}
      <Loader></Loader>
      <div>Enviando</div>
    </ContainerCenter>
  );
}

export default Loading;
