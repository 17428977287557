import styled from "styled-components";


export const BlogDiv = styled.div`
    padding: 20px;
`;

export const Title1 = styled.h1`
  font-size: 1.4em;
  display: inline-block;
  color: var(--corb4);

  @media screen and (max-width:500px) {
    font-size: 0.6em;
  }

  @media screen and (max-width:770px){
    font-size: 1em;
  }

`;

export const Title2 = styled.h2`
  padding-left:20px;
  font-size: 1.4em;
  display: inline-block;
  color: var(--corb5); 
  
  @media screen and (max-width:500px) {
    font-size: 0.6em;
  }

  @media screen and (max-width:770px){
    font-size: 1em;
  }

`;

export const TitleSector = styled(Title1)`
   margin:10px;
   text-transform:capitalize;
`;

export const MenuList = styled.ul`
    margin: 10px 0px;
    font-size: 0.6em;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    gap: 3px;
`;

export const MenuItem = styled.li`
    &:nth-child(1){
        text-transform:capitalize;
        font-weight:bold;
    }
`;

export const MenuAction = styled.a`
        text-decoration: none;
        color: var(--corb4);

        &:hover{
            color: red;
            text-decoration: underline;
            color: red;
        }
`;

export const BlogSection = styled.section` 
    border-bottom: 1px solid var(--corb4);
    padding: 10px
`;


