import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const images = [
  {
    original:
      './image/atelie/categoria/infantil/prendedor-de-cabelo/pred_amarelo.jpg',
    thumbnail:
      './image/atelie/categoria/infantil/prendedor-de-cabelo/thumbnail/pred_amarelo.jpg',
    description: 'Prendedor de Cabelo Amarelo',
  },
  {
    original:
      './image/atelie/categoria/infantil/prendedor-de-cabelo/pred_rosa.jpg',
    thumbnail:
      './image/atelie/categoria/infantil/prendedor-de-cabelo/thumbnail/pred_rosa.jpg',
    description: 'Prendedor de Cabelo Roso',
  },
  {
    original:
      './image/atelie/categoria/infantil/prendedor-de-cabelo/pred_vermelho.jpg',
    thumbnail:
      './image/atelie/categoria/infantil/prendedor-de-cabelo/thumbnail/pred_vermelho.jpg',
    description: 'Prendedor de Cabelo Vermelho',
  },
];
function Slide() {
  return (
    <div>
      <ImageGallery items={images} autoPlay={false} thumbnailPosition="left" />
    </div>
  );
}

export default Slide;
