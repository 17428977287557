/* eslint-disable no-irregular-whitespace */
import { createGlobalStyle } from 'styled-components';

const Base = createGlobalStyle`
body,
div,
h1,
h2,
p,
span,
section,
nav,
ul,
li,
figure,
footer,
header,
img {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  /* Descoonsidera o tamanho de padding e border element*/
}

:root {
  /* Cor Monocromática */
  --corb1: #F8CACC;
  --corb2: #eeacaf;
  --corb3: #F7C8CB;
  --corb4: #793032;
  --corb5: #c98386;

  /* Lilas */
  /* --corb1: #e1b7f3;
  --corb2: #A872BE;
  --corb3: #C787E0;
  --corb4: #201f20;
  --corb5: #9A68AD; */

  --corb6: rgb(241, 184, 184);
  --corb7: rgb(39, 37, 37);
}

body {
  background-color: var(--corb1);
  font-family: 'Dancing Script', cursive;
  font-size: 1.5em;
  color: var(--corb4);
}
${
  '' /* 
Será 5 breakpoints 
320px  —  480px: dispositivos móveis
481px  —  768px: iPads, tablets
769px — 1024px: telas pequenas, laptops
1025px — 1200px: desktops, telas grandes
1201px e acima —  telas muito grandes, TVs
Desktop para mobile */
}


@media screen and (min-width:1201px) {
  .container {
    width: 100%;
    max-width: 1600px;
    margin: auto;
  }
}`;

export default Base;
