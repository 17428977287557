import styled from 'styled-components';

const FieldGroup = styled.div`
  color: var(--corb4);
  display: flex;
  align-items: stretch;
  flex-flow: column wrap;
  row-gap: 5px;

  p {
    color: red;
  }

  label {
    align-self: center;
  }
  input,
  textarea,
  select {
    padding: 10px;
    border-radius: 10px;
    font-size: 0.8em;
  }
  select {
    background: var(--corb4);
    color: #ffffff;
    text-align: center;
    cursor: pointer;
  }

  input {
    margin-bottom: 3px;
  }

  textarea {
    margin-top: 5px;
  }
`;

export default FieldGroup;
