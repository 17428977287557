import './index.css'

function Cadastro() {
    return (
        <h1>Cadastro</h1>
    )
}

export default Cadastro;

