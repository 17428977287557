import styled from 'styled-components';

const ButtonStyled = styled.button`
  box-sizing: border-box;
  padding: 10px;
  height: 50px;
  width: 100%;
  font-size: large;
  border-radius: 10px;
  margin-top: 5px;
  background-color: var(--corb4);
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
`;

function Button() {
  return <ButtonStyled type="submit">Enviar</ButtonStyled>;
}

export default Button;
