import React from 'react';
import { Link } from 'react-router-dom';
import {
  Paragrafo,
  TitleC,
  Container,
  Image,
  Gallery,
} from './VoltaAulasStyle';

import ImgPen1 from './img/voltaaulas/caneta-1.jpg';
import ImgPen2 from './img/voltaaulas/caneta-2.jpg';
import ImgPen3 from './img/voltaaulas/caneta-3.jpg';
import ImgPen4 from './img/voltaaulas/caneta-4.jpg';
import ImgPen5 from './img/voltaaulas/caneta-5.jpg';

function VoltaAula() {
  return (
    <Container>
      <TitleC>Volta às Aulas 2022</TitleC>

      <Paragrafo>
        A volta às aulas é um momento muito animado para a criançada. Depois das
        férias está na hora de rever os amigos e professores. Quem é apaixonado
        por arte, está é uma grande oportunidade de unir esse amor com os
        materiais necessários para o colégio.
      </Paragrafo>
      <Paragrafo>
        Contudo trago a vocês uma ótima opção para esse reencontro escolar,
        essas lindas Canetas personalizadas, tanto para os alunos como também
        professores que gostam de artesanato.
      </Paragrafo>
      <Paragrafo>
        Venha conferir as diversas opções de canetas em nosso Ateliê.
      </Paragrafo>
      <Link to="/atelie#escolar"> Entre no Atelie</Link>
      <Gallery>
        <Image src={ImgPen1} />
        <Image src={ImgPen2} />
        <Image src={ImgPen3} />
        <Image src={ImgPen4} />
        <Image src={ImgPen5} />
      </Gallery>
      <Link to="/atelie#escolar"> Entre no Atelie</Link>
      {/* Botao Compartilhar */}
      <div id="fb-root" />
      <script
        async
        defer
        crossOrigin="anonymous"
        src="https://connect.facebook.net/pt_BR/sdk.js#xfbml=1&version=v14.0&appId=599442623536742&autoLogAppEvents=1"
        nonce="RpCLxBHq"
      />
      <div
        className="fb-share-button"
        data-href="http://www.michellelimas.com.br/blog#moda"
        data-layout="button"
        data-size="large"
      >
        <a
          target="_blank"
          href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fwww.michellelimas.com.br%2Fblog%23moda&amp;src=sdkpreparse"
          className="fb-xfbml-parse-ignore"
          rel="noreferrer"
        >
          Compartilhar no Facebook
        </a>
      </div>
    </Container>
  );
}

export default VoltaAula;
