import './index.css';
import { Link } from 'react-router-dom';

function Login() {
  return (
    <>
      <h1>Login</h1>
      <form action="/login" method="post">
        <input type="text" placeholder=" Email" />
        <input type="text" placeholder=" Senha" />
        <button type="submit">Entrar</button>
      </form>
      <p>
        <Link to="/recupera-login"> Esqueceu a senha!</Link>
      </p>
      <p>
        <Link to="/cadastro">É novo! Cadastre-se.</Link>
      </p>
    </>
  );
}

export default Login;
