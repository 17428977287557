import { Link } from 'react-router-dom';
import Gallery from './historia/Slide';
import VoltaAulas2022 from './moda/2022/julho/VoltaAula';
import {
  BlogDiv,
  Title1,
  Title2,
  TitleSector,
  BlogSection,
  MenuList,
  MenuItem,
  MenuAction,
} from './BlogStyle';

function Blog() {
  return (
    <BlogDiv>
      <Title1>Blog.</Title1>
      <Title2>Para você e seu filho. </Title2>
      <MenuList>
        <MenuItem>Categorias:</MenuItem>
        <MenuItem>
          <MenuAction href="#infantil">História Infantil</MenuAction>
        </MenuItem>
        <MenuItem>
          <MenuAction href="#moda"> Moda / Informações</MenuAction>
        </MenuItem>
      </MenuList>
      <BlogSection id="infantil">
        <TitleSector>História Infantil</TitleSector>
        <article className="artigo-secondary">
          <h1>O Peixinho e o Pássaro</h1>

          <p className="p-fabula">
            {' '}
            Lino era um peixinho que vivia em uma lagoa e sonhava em voar pelo
            céu junto com os outros pássaros e fazer todas aquelas acrobacias no
            ar, mas percebia que era impossível, pois não teria como respirar
            além de não ter asas para voar.
          </p>
          <Gallery />
          <Link className="btnSaiba" to="/fabula">
            Saiba mais
          </Link>
        </article>
      </BlogSection>
      <BlogSection id="moda">
        <TitleSector>Moda / Informações</TitleSector>
        <VoltaAulas2022 />
      </BlogSection>
    </BlogDiv>
  );
}

export default Blog;
