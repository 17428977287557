import React from 'react';
import { Link } from 'react-router-dom';
import styled from './HomeAtelie.module.css';

function HomeAtelie() {
  return (
    <div className={styled.central}>
      <div className={styled.miniCentral}>
        <h1 className={styled.titleCentral}>
          Venha conhecer nosso trabalho em crochê.
        </h1>
        <Link className={styled.btnCentral} to="/atelie">
          Click aqui !
        </Link>
      </div>
    </div>
  );
}

export default HomeAtelie;
