/* eslint-disable react/jsx-no-bind */
import { useState } from 'react';

import Section from './components/SectionMain';
import Form from './components/Form';
import RecebidoEmail from './components/RecebidoEmail';

function Contato() {
  const [showSend, setShowSend] = useState(true);

  function show() {
    setShowSend(false);
  }

  return (
    <Section>
      {showSend && <Form showSendInput={show} />}
      {!showSend && <RecebidoEmail />}
    </Section>
  );
}

export default Contato;
