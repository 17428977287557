import './index.css';
import { Link } from 'react-router-dom';

function QuemSomos() {
  return (
    <div className="sobre-nos">
      <h1 className="sobre__title">Sobre nós</h1>
      <p className="sobre__paraf">
        {' '}
        <strong>Michelle Lima </strong>é pedagoga e também artesã, pois
        desenvolve produtos em crochê. Por isso, estou disponibilizando no
        website histórias infantis e os produtos artesanais. Meu espaço para
        mostrar os crochês é &quot; Michelle Ateliê &ldquo; .
      </p>
      <p className="sobre__paraf">
        O <strong>&quot; Michelle Ateliê &ldquo; </strong> desenvolve produtos
        personalizados em crochê. Tudo feito com muito amor. Pelas mãos da
        artesã Michelle Lima.
      </p>
      <p className="sobre__paraf">
        Então, oferemos chaveiros, canetas, toalhas, panos de pratos e diversos
        outros produtos para atender nossos clientes.{' '}
      </p>

      <p className="sobre__paraf">
        Estamos localizado na cidade de Sobral, estado do Ceará, Brasil. Confira
        nossos produtos!!!{' '}
        <Link to="atelie" className="sobre__link">
          {' '}
          Clique aqui{' '}
        </Link>
      </p>
    </div>
  );
}

export default QuemSomos;
