/* eslint-disable */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import styled from 'styled-components';

import FormContato from './style';
import FieldGroup from '../FieldGroup';
import Button from '../Button';
import Title from '../Title';
import Loading from '../Loading';

function Form({ showSendInput }) {
  const [viewLoading, setViewLoading] = useState(true);
  const [recapt, setRecapt] = useState('indefinido');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const host = process.env.REACT_APP_URL;

  const onSubmitLocal = (data) => {
    data.recaptcha = recapt;

    setViewLoading(false);

    let myInit = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: new Headers({
        'Content-type': 'application/json',
      }),
    };
    const urlBase = `${host}/contato`;
    fetch(urlBase, myInit)
      .then((data) => data.json())
      .then((response) => {
        console.log(response);
        showSendInput();
      })
      .catch((error) => {
        console.error(error);
      });

    return true;
  };

  const Div = styled.div`
    height: 70px;
    margin-top: 5px;
  `;

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_SITE_KEY}
      scriptProps={{ async: true }}
      container={{
        // optional to render inside custom element
        element: 'containerReCaptcha',
        parameters: {
          badge: 'inline', // optional, default undefined
        },
      }}
    >
      <Title content="Fale conosco!" />
      {viewLoading && (
        <FormContato
          method="post"
          onSubmit={handleSubmit(onSubmitLocal)}
          id="formContact"
        >
          <FieldGroup>
            <input
              type="text"
              placeholder="Seu nome ... "
              {...register('nome', { required: true })}
            />
            {errors?.nome?.type === 'required' && <p>Nome é obrigatório</p>}
          </FieldGroup>
          <FieldGroup>
            <input
              type="email"
              placeholder="Seu email ...  exemplo@email.com"
              {...register('email', {
                required: true,
                validate: (value) => validator.isEmail(value),
              })}
            />
            {errors?.email?.type === 'required' && <p>Email é obrigatório</p>}
            {errors?.email?.type === 'validate' && <p>Email é inválido</p>}
          </FieldGroup>
          <FieldGroup>
            <input
              type="txt"
              placeholder="Whatsapp  (88) 9.9999-9999"
              {...register('whatsapp', {
                required: false,
              })}
            />
          </FieldGroup>

          <FieldGroup>
            <textarea
              placeholder=" MENSAGEM"
              rows="10"
              {...register('mensagem', { required: true })}
            />
            {errors?.mensagem?.type === 'required' && (
              <p>Mensagem é obrigatório</p>
            )}
          </FieldGroup>

          <FieldGroup>
            <input
              type="hidden"
              value={recapt}
              {...register('recaptcha', {
                required: true,
              })}
            />
          </FieldGroup>
          <Button />

          <Div id="containerReCaptcha"> </Div>
        </FormContato>
      )}
      {!viewLoading && <Loading />}

      <GoogleReCaptcha
        onVerify={(token) => {
          // console.log(token);
          setRecapt(token);
        }}
      />
    </GoogleReCaptchaProvider>
  );
}
export default Form;
