/* eslint-disable */
import { BrowserRouter } from 'react-router-dom';
import Menu from './components/menu/menu';
import Footer from './components/Footer/footer';
import Pages from './pages/routes';

// import Loading from './pages/contato/components/Loading';

function App() {
  return (
    <div className="container">
      <BrowserRouter>
        <Menu />
        <Pages />
        <Footer />
      </BrowserRouter>
      {/* <Loading /> */}
    </div>
  );
}

export default App;
