import './index.css'

function Atendimento() {
    return (
        <>
            <h1>Atendimento</h1>
            <p>Formatar conteudo</p>
        </>
    )
}

export default Atendimento;